import { useSelector } from 'react-redux'

import NavbarTab from '@tabeeb/modules/navbar/components/NavbarTab'
import routes from '@tabeeb/routes'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { TenantPermission } from '@tabeeb/enums'

const BulkImportTab = ({ ...rest }) => {
  const display = useSelector((state) => hasTenantPermission(state, TenantPermission.BulkImport))

  const props = {
    item: {
      label: 'Bulk import',
      to: routes.bulkImports,
    },
    display,
    ...rest,
  }

  return <NavbarTab {...props} />
}

export default BulkImportTab
